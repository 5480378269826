<template>
    <VGridPageColumn sm-2 xs-12 width="15" border>
        <div class="nav-menu">
            <div class="nav-menu__logo">
                <RouterLink class="nav-menu__logo-src" to="/"></RouterLink>
            </div>
            <div class="nav-menu__header">
                <RouterLink
                    :to="{
                        name: 'request.create',
                    }"
                    class="button button_green button_fluid"
                    >Создать обращение</RouterLink>
            </div>
            <div class="nav-menu__list">
                <ul>
                    <template v-for="(item, index) in items">
                        <li :key="index" class="nav-menu__item" :class="item.class">
                            <div class="nav-menu__devider" v-show="index === 3">
                                <DeviderSVG></DeviderSVG>
                            </div>
                            <RouterLink :to="item.to" class="nav-menu__item-link" nav-menu>
                                <component :is="item.icon"></component>
                                <span class="nav-menu__item-label">
                                    {{ item.label }} <span v-show="item.counter" class="nav-menu__item-label-counter">{{ item.counter }}</span>
                                </span>
                            </RouterLink>
                            <div class="nav-menu__devider" v-show="index === 3">
                                <DeviderSVG></DeviderSVG>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="nav-menu__user">
            <ul class="nav-menu__user-list">
                <li class="nav-menu__item">
                    <div class="nav-menu__item-link-account">
                        <UserCard :account="$_account" />
                    </div>
                </li>
                <div class="nav-menu__devider">
                    <DeviderSVG></DeviderSVG>
                </div>
                <li class="nav-menu__item" v-if="$_isExecutor || $_isAdmin">
                    <RouterLink :to="{ name: 'admin-panel.users' }" class="nav-menu__user-item-link" nav-menu>
                        <SettingsSVG></SettingsSVG>
                        <span class="nav-menu__item-label">
                            Админ-панель
                        </span>
                    </RouterLink>
                </li>
                <li class="nav-menu__item" @click="logout">
                    <div class="nav-menu__user-item-link">
                        <LogoutSVG></LogoutSVG>
                        <span class="nav-menu__item-label">Выйти</span>
                    </div>
                </li>
            </ul>
        </div>
    </VGridPageColumn>
</template>
<script>
// Components
import VButton from '../../components/VButton/VButton.vue';
import VGridPageColumn from '../../components/VGrid/VGridPageColumn';
import UserCard from '@/views/components/VUser/UserCard.vue';

//SVG
import IncomingRequestsSVG from '@/images/incoming_requests.svg';
import OutcomingRequestsSVG from '@/images/outcoming_requests.svg';
import ArchiveSVG from '@/images/archive.svg';
import AllRequestsSVG from '@/images/all_requests.svg';
import DeviderSVG from '@/images/devider.svg';
import SettingsSVG from '@/images/settings.svg';
import LogoutSVG from '@/images/logout.svg';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import oidc from '../../../vue-oidc-client';

const Workspace = namespace('workspace');

@Component({
    components: {
        VButton,
        VGridPageColumn,
        UserCard,
        DeviderSVG,
        SettingsSVG,
        LogoutSVG,
    },
})
class NavMenu extends Vue {
    @Workspace.State menuInfo;
    @Workspace.State accessSecurityGroups;

    logout() {
        oidc.signOut();
    }

    get items() {
        const items = [
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'inbox',
                    },
                },
                icon: IncomingRequestsSVG,
                label: 'Входящие',
                counter: this.menuInfo?.inbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'outbox',
                    },
                },
                icon: OutcomingRequestsSVG,
                label: 'Исходящие',
                counter: this.menuInfo?.outbox ?? 0,
            },
            {
                to: {
                    name: 'request',
                    params: {
                        method: 'archive',
                    },
                },
                icon: ArchiveSVG,
                label: 'Архив',
            },
        ];

        if (this.$_isExecutor || this.$_isAdmin) {
            items.push({
                to: {
                    name: 'request',
                    params: {
                        method: 'store',
                    },
                },
                icon: AllRequestsSVG,
                label: 'Всe обращения',
                class: 'nav-menu__item_space-top',
            });
        }

        const groups = this.accessSecurityGroups ?? {};

        if (groups) {
            let groupKeys = [];

            if (this.menuInfo?.groups) {
                groupKeys = Object.keys(this.menuInfo.groups);
            }

            groups.forEach((group, index) => {
                let counter = 0;

                if (groupKeys.find((item) => item == group.id)) {
                    counter = this.menuInfo.groups[group.id];
                }

                const item = {
                    to: {
                        name: 'request',
                        params: {
                            method: group.id,
                        },
                    },
                    icon: AllRequestsSVG,
                    label: group.name,
                    counter: counter,
                    class: index === 0 ? 'nav-menu__item_space-top' : '',
                };

                items.push(item);
            });
        }

        return items;
    }
}

export default NavMenu;
</script>
<style lang="scss">
.router-link-active[nav-menu] {
    background: #f1f6fe;
}

.nav-menu {
    &__list{
        overflow-y: auto;
        max-height: 55vh;
        margin: 0 10px;
    }

    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 12px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    &__header{
        margin: 0 10px 14px 10px;
        .button{
            font-size: 16px;
            border-radius: 10px;
        }
    }

    &__item {
        width: 100%;
        height: auto;
        min-height: 40px;
        margin-bottom: 4px;
        font-size: 1rem;
        color: var(--text-black);
    }

    &__item-link {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding: 0 16px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        & svg{
            width: 24px;
            height: 24px;
            color: #484848;
        }
        &:hover {
            background: #F8F8F8;
        }

        &-account{
            &:hover {
                background: #ffffff;
            }
            padding: 0 16px;
           .user-card{
                height: auto;
                &__label-name{
                    white-space: normal;
                    overflow: visible;
                    text-overflow: clip;
                    font-size: 1rem;
                    &_wide{
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }

    &__item-label {
        margin-left: 8px;
        display: flex;

        &-counter{
            position: absolute;
            right: 0;
            margin-right: 10px;
            background-color: #00A55A;
            border-radius: 12px;
            padding: 0 7px;
            color: #FFFFFF;
        }
    }

    &__logo{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
    }

    &__logo-src {
        display: block;
        width: 158px;
        height: 44px;
        background: url(~@/images/talan-logo.svg?url) center center no-repeat;
        background-size: 100%;
        z-index: 100;
    }

    &__devider{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin: 15px 0;
    }

    &__user{
        position: absolute;
        bottom: 0;
        left: 0;
        align-self: flex-end;
        display: flexbox;
        margin: 0 10px;

    }

    &__user-item-link{
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 40px;
        padding: 0 16px;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        & svg{
            height: 24px;
            width: 24px;
            color: #484848;
        }
        &:hover {
            background: #F8F8F8;
        }
    }
}
</style>
